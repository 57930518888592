import React, { ReactNode } from 'react';
import { ICompany } from '../../graphql/queries/getCompanyInfoQuery';

import { Container, Header, Section } from './style';

interface TemplateProps {
  company: ICompany;
  creditType: string;
  children: ReactNode;
}

function Template({
  children,
  company,
  creditType,
}: TemplateProps): JSX.Element {
  return (
    <Container>
      <Header>
        <h1>{company.name}</h1>
        <h1>{`Solicite seu crédito ${creditType}`}</h1>
      </Header>
      <Section>{children}</Section>
    </Container>
  );
}

export default Template;
