import { ApolloError, useMutation } from '@apollo/client';
import { CheckCircleOutline } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import InputDate from '../../../../../components/InputDate';
import InputNumber from '../../../../../components/InputNumber';
import { CPFCNPJInputNumber } from '../../../../../components/InputNumber/CPFCNPJInputNumber';
import Select from '../../../../../components/Select';
import {
  createExchangeDealForLegalPersonMutation,
  ICreateExchangeDealForLegalPersonMutationResponse,
  ICreateExchangeDealForLegalPersonMutationVars,
} from '../../../../../graphql/mutations/createPartnerExchangeDealForLegalPerson';
import { FormContainer } from '../../../../../Templates/Credit/style';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { validateCNPJ } from '../../../../../utils/validateCNPJ';
import { useExchangeCredit } from '../../../hooks/ExchangeCredit';
import { brazilianStatesTypes } from '../IndividualForm/types/brazilianStatesTypes';

interface ILegalEntitiesFormData {
  name: string;
  birthday: Date;
  cnpj: string;
  email: string;
  opportunityValue: number;
  corporateName: string;
  fantasyName: string;
  companyAddress: string;
  addressNumber: string;
  complement: string;
  district: string;
  city: string;
  uf: string;
  zipCode: string;
  phone: string;
  cellPhone: string;
  mainActivity: string;
}

interface CreditFormProps {
  companyID: string;
}

function LegalEntitiesForm({ companyID }: CreditFormProps): JSX.Element {
  const formRef = useRef<FormHandles>(null);

  const { personType } = useExchangeCredit();
  const history = useHistory();

  const handleOnCreateExchangeDealForLegalPersonCompleted = useCallback(() => {
    const successMessage = 'Requisição de câmbio realizada com sucesso';
    toast.success(successMessage);
    history.push(`/obrigado`);
  }, [history]);

  const handleOnCreateExchangeDealForLegalPersonMutationError = useCallback(
    ({ message }: ApolloError) => {
      const errorMessage = `Não foi possível realizar a solicitação de crédito: ${message}`;
      toast.warning(errorMessage);
    },
    [],
  );

  const [
    createExchangeDealForLegalPerson,
    { loading: isRequestLoading },
  ] = useMutation<
    ICreateExchangeDealForLegalPersonMutationResponse,
    ICreateExchangeDealForLegalPersonMutationVars
  >(createExchangeDealForLegalPersonMutation, {
    onCompleted: handleOnCreateExchangeDealForLegalPersonCompleted,
    onError: handleOnCreateExchangeDealForLegalPersonMutationError,
  });
  const handleOnSubmit = useCallback(
    async (data: ILegalEntitiesFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          name: yup
            .string()
            .required('Você precisa informar o seu nome completo'),
          birthday: yup
            .date()
            .required('Você precisar informar o sua data de nascimento'),
          cnpj: yup
            .string()
            .required('Você precisa fornecer o seu CNPJ')
            .test('cnpj', 'Você precisa informar um CNPJ válido', (cnpj) =>
              validateCNPJ(cnpj || ''),
            ),
          email: yup
            .string()
            .required('Você precisa fornecer o seu email')
            .email('Insira um email válido'),
          opportunityValue: yup
            .number()
            .typeError('Você precisa fornecer um valor numérico')
            .required(
              'Você precisa fornecer um valor pretendido para empréstimo',
            )
            .positive('Por favor, insira um valor positivo'),
          corporateName: yup
            .string()
            .required('Você precisa informar o nome da razão social'),
          fantasyName: yup
            .string()
            .required('Você precisa informar o nome fantasia'),
          companyAddress: yup
            .string()
            .required('Você precisa informar o endereço da empresa.'),
          addressNumber: yup
            .string()
            .required('Você precisa informar o numero do endereço da empresa'),
          district: yup
            .string()
            .required('Você precisa informar o bairro da empresa'),
          city: yup
            .string()
            .required(
              'Você precisa informar a cidade onde se localiza a empresa',
            ),
          uf: yup
            .string()
            .required(
              'Você precisa informar a UF do estado da localização da empresa',
            ),
          zipCode: yup
            .string()
            .required('Você precisa informar o CEP do endereço da empresa'),
          phone: yup
            .string()
            .required(
              'Você precisa informar o numero do telefone fixo da empresa',
            ),
          cellPhone: yup
            .string()
            .required('Você precisa informar o telefone celular da empresa'),
          mainActivity: yup
            .string()
            .required('Você precisa informar a atividade principal da empresa'),
        });
        await schema.validate(data, { abortEarly: false });
        await createExchangeDealForLegalPerson({
          variables: {
            ...data,
            companyID,
            personType,
            opportunityValue: parseFloat(String(data.opportunityValue)),
          },
        });
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        const errorMessage =
          'Houve um erro ao processar os dados! Por favor, entre em contato com o suporte';
        toast.warn(errorMessage);
      }
    },
    [companyID, createExchangeDealForLegalPerson, personType],
  );
  return (
    <FormContainer ref={formRef} onSubmit={handleOnSubmit}>
      <Input name="name" label="Nome" fullWidth />
      <InputDate name="birthday" label="Data de Nascimento" />
      <CPFCNPJInputNumber name="cnpj" label="CNPJ" />
      <Input name="email" label="E-mail" />
      <InputNumber
        name="opportunityValue"
        label="Valor do crédito pessoal"
        prefix="R$ "
      />
      <Input name="corporateName" label="Razão social" />
      <Input name="fantasyName" label="Nome fantasia" />
      <Input name="companyAddress" label="Endereço da empresa" />
      <Input name="addressNumber" label="N°" />
      <Input name="complement" label="Complemento°" />
      <Input name="district" label="Bairro" />
      <Input name="city" label="Cidade" />
      <Select name="uf" options={brazilianStatesTypes} label="UF" />
      <InputNumber name="zipCode" label="CEP" format="#####-###" />
      <InputNumber name="phone" label="Telefone Fixo" format="(##) ####-####" />
      <InputNumber
        name="cellPhone"
        label="Telefone celular"
        format="(##) 9####-####"
      />
      <Input name="mainActivity" label="Atividade Principal" />
      <Button
        icon={CheckCircleOutline}
        type="submit"
        disabled={isRequestLoading}
      >
        {isRequestLoading ? 'Realizando Requisição' : 'Quero Crédito'}
      </Button>
    </FormContainer>
  );
}

export { LegalEntitiesForm };
