import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: stretch;
  padding: 1rem;
  background-color: ${colors.background.first};
  color: ${colors.text.first};
`;

export const Section = styled.article`
  flex: 1;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-auto-flow: row;
  gap: 1rem;

  & > * {
    width: 100%;
  }

  & > h1 {
    text-align: center;
  }

  & > h2 {
    text-align: justify;
    font-size: 1rem;
  }

  & > small {
    text-align: right;
  }

  & p {
    text-align: justify;
  }

  & ul {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    padding: 0 1rem;
  }

  & ul > li {
    text-align: justify;
  }

  & a {
    text-decoration: none;
    color: ${colors.success.third};
    word-break: break-all;
  }
`;
