const colors = {
  primary: {
    light: {
      first: '#C8E3DA',
      second: '#B5D9CE',
      third: '#A3D0C2',
    },
    normal: {
      first: '#46A184',
      second: '#3F9177',
      third: '#38816A',
    },
    dark: {
      first: '#31715C',
      second: '#2A614F',
      third: '#235142',
    },
    darker: {
      first: '#153028',
    },
  },
  secondary: {
    light: {
      first: '#BDD3E2',
      second: '#A7C4D9',
      third: '#92B6CF',
    },
    normal: {
      first: '#246C9F',
      second: '#20618F',
      third: '#1D567F',
    },
    dark: {
      first: '#16415F',
      second: '#123650',
      third: '#0E2B40',
    },
    darker: {
      first: '#071620',
    },
  },
  success: {
    first: '#CCF8EB',
    second: '#02DB9A',
    third: '#02AF7B',
  },
  error: {
    first: '#F8CCD9',
    second: '#DB0242',
    third: '#AF0235',
  },
  warning: {
    first: '#FCE7CD',
    second: '#F18805',
    third: '#C16D04',
  },
  info: {
    first: '#BDD3E2',
    second: '#246C9F',
    third: '#16415F',
  },
  text: {
    first: '#F6F9FB',
    second: '#A6ACBE',
    third: '#363C4C',
    fourth: '#071620',
  },
  background: {
    first: '#071620',
    second: '#E9F0F5',
  },
};

export default colors;
