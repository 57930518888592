import { NaturePeople } from '@material-ui/icons';
import React from 'react';

import { Container, Content } from './style';

function NotFound(): JSX.Element {
  return (
    <Container>
      <Content>
        <h2>Página não encontrada!</h2>
        <h4>Por favor, contate a empresa para informar o endereço correto.</h4>
        <NaturePeople />
      </Content>
    </Container>
  );
}

export default NotFound;
