import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Imobiliario from '../pages/RealEstate';
import NotFound from '../pages/NotFound';
import Obrigado from '../pages/Obrigado';
import Privacy from '../pages/Policies/Privacy';
import TermsAndConditions from '../pages/Policies/TermsAndConditions';
import Vehicular from '../pages/Vehicular';
import { PersonalCredit } from '../pages/PersonalCredit';
import { Consigned } from '../pages/Consigned';
import { Exchange } from '../pages/Exchange';
import { PrepaymentOfReceivables } from '../pages/PrepaymentOfReceivables';
import { FGTSWithdrawal } from '../pages/FGTSWithdrawal';

function Routes(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/cambio/:id" component={Exchange} />
      <Route exact path="/consignado/:id" component={Consigned} />
      <Route exact path="/imobiliario/:id" component={Imobiliario} />
      <Route
        exact
        path="/antecipacao-de-recebiveis/:id"
        component={PrepaymentOfReceivables}
      />
      <Route exact path="/pessoal/:id" component={PersonalCredit} />
      <Route exact path="/veicular/:id" component={Vehicular} />
      <Route exact path="/saque-fgts/:id" component={FGTSWithdrawal} />
      <Route exact path="/obrigado" component={Obrigado} />
      <Route path="/sobre/privacidade" component={Privacy} />
      <Route path="/sobre/termos" component={TermsAndConditions} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
