export const relevanteRoleTypes = [
  {
    value: '399',
    label: 'Sim',
  },
  {
    value: '401',
    label: 'Não',
  },
];
