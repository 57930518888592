import { gql } from '@apollo/client';

export interface ParamsProps {
  id: string;
}

export interface ICreateFGTSWithdrawalMutationVars {
  birthday: Date;
  companyID: string;
  cpf: string;
  email: string;
  name: string;
  phone: string;
  employmentDate: Date;
  opportunityValue: number;
}

export interface ICreateFGTSWithdrawalData {
  TITLE: string;
}

export interface ICreateFGTSWithdrawalMutationResponse {
  ID: string;
}

const createFGTSWithdrawalMutation = gql`
  mutation createFGTSWithdrawal(
    $companyID: String!
    $birthday: DateTime!
    $cpf: String!
    $email: String!
    $name: String!
    $phone: String!
    $employmentDate: DateTime!
    $opportunityValue: Float!
  ) {
    createFGTSWithdrawal(
      data: {
        companyID: $companyID
        birthday: $birthday
        cpf: $cpf
        email: $email
        name: $name
        phone: $phone
        employmentDate: $employmentDate
        opportunityValue: $opportunityValue
      }
    ) {
      ID
    }
  }
`;

export { createFGTSWithdrawalMutation };
