import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { BaseTextFieldProps } from '@material-ui/core';
import { TextFieldCustomized } from './style';

interface InputProps extends BaseTextFieldProps {
  name: string;
}

function Input({ name, ...rest }: InputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <TextFieldCustomized
      inputRef={inputRef}
      variant="filled"
      inputProps={{
        name: fieldName,
        value: defaultValue,
      }}
      error={!!error}
      helperText={error}
      fullWidth
      {...rest}
    />
  );
}

export default Input;
