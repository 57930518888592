import { ApolloError, useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import InputDate from '../../../components/InputDate';
import InputNumber from '../../../components/InputNumber';
import { CPFCNPJInputNumber } from '../../../components/InputNumber/CPFCNPJInputNumber';
import {
  createFGTSWithdrawalMutation,
  ICreateFGTSWithdrawalMutationResponse,
  ICreateFGTSWithdrawalMutationVars,
} from '../../../graphql/mutations/createFGTSWithdrawalMutation';
import { FormContainer } from '../../../Templates/Credit/style';
import getValidationErrors from '../../../utils/getValidationErrors';
import { validateCNPJ } from '../../../utils/validateCNPJ';
import validateCPF from '../../../utils/ValidateCPF';

interface FGTSWithdrawalFormProps {
  companyID: string;
}

function FGTSWithdrawalForm({
  companyID,
}: FGTSWithdrawalFormProps): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleOnMutationCompleted = useCallback(() => {
    const successMessage =
      'A requisição do saque do FGTS foi realizada com sucesso!';
    toast.success(successMessage);
    history.push('/obrigado');
  }, [history]);

  const handleOnMutationError = useCallback(({ message }: ApolloError) => {
    toast.error(
      `Houve um erro ao realizar a requisição do sague do fgts. Erro: ${message}`,
    );
  }, []);

  const [createFGTSWithdrawal, { loading: isMutationLoading }] = useMutation<
    ICreateFGTSWithdrawalMutationResponse,
    ICreateFGTSWithdrawalMutationVars
  >(createFGTSWithdrawalMutation, {
    onCompleted: handleOnMutationCompleted,
    onError: handleOnMutationError,
  });

  const handleOnSubmit = useCallback(
    async (data: Omit<ICreateFGTSWithdrawalMutationVars, 'companyID'>) => {
      try {
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          name: yup
            .string()
            .required('Você precisa fornecer o seu nome completo'),
          birthday: yup
            .date()
            .required('Você precisar informar o sua data de nascimento'),
          opportunityValue: yup
            .number()
            .typeError('Você precisa fornecer um valor numérico')
            .required(
              'Você precisa fornecer um valor pretendido para empréstimo',
            )
            .positive('Por favor, insira um valor positivo'),
          cpf: yup
            .string()
            .required('Você precisa fornecer o seu CPF/CNPJ')
            .test(
              'cpf',
              'Você precisa informar um CPF/CNPJ válido',
              (cpf) => validateCPF(cpf || '') || validateCNPJ(cpf || ''),
            ),
          phone: yup.string().required('Você precisa inserir o seu telefone'),
          email: yup
            .string()
            .required('Você precisa fornecer o seu email')
            .email('Insira um email válido'),
          employmentDate: yup
            .date()
            .required('Você precisa fornecer a data de sua admissão'),
        });

        await schema.validate(data, { abortEarly: false });
        await createFGTSWithdrawal({
          variables: {
            ...data,
            companyID,
            opportunityValue: parseFloat(String(data.opportunityValue)),
          },
        });
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        const errorMessage =
          'Houve um erro ao processar os dados! Por favor, entre em contato com o suporte';
        toast.error(errorMessage);
      }
    },
    [companyID, createFGTSWithdrawal],
  );

  return (
    <FormContainer ref={formRef} onSubmit={handleOnSubmit}>
      <Input name="name" label="Nome" fullWidth />
      <InputDate name="birthday" label="Data de nascimento" />
      <InputNumber name="phone" label="Telefone" format="(##) # ####-####" />
      <Input name="email" label="Email" />
      <CPFCNPJInputNumber name="cpf" label="CPF" mask="" />
      <InputDate name="employmentDate" label="Data de Admissão" />
      <InputNumber
        name="opportunityValue"
        label="Valor pretendido para saque"
        prefix="R$ "
      />
      <Button disabled={isMutationLoading} type="submit" color="primary">
        {isMutationLoading ? 'Solicitando Saque' : 'Quero Crédito'}
      </Button>
    </FormContainer>
  );
}

export { FGTSWithdrawalForm };
