import React, { useCallback, useRef } from 'react';
import { CheckCircleOutline } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ApolloError, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import InputNumber from '../../../../components/InputNumber';
import Select from '../../../../components/Select';
import { Fieldset, FormContainer } from '../../../../Templates/Credit/style';
import financingTypes from './SelectTypes/financingTypes';
import propertyTypes from './SelectTypes/propertyTypes';
import getValidationErrors from '../../../../utils/getValidationErrors';

import TermsAndConditionsMessage from '../../../../components/TermsAndConditionsMessage';
import ADD_REAL_ESTATE_CONTACT, {
  AddRealEstateContactMutationResponse,
  AddRealEstateContactMutationVars,
} from '../../../../graphql/mutations/addRealEstateContactMutation';
import InputDate from '../../../../components/InputDate';
import personTypes from './SelectTypes/personTypes';
import { CPFCNPJInputNumber } from '../../../../components/InputNumber/CPFCNPJInputNumber';
import validateCPF from '../../../../utils/ValidateCPF';
import { validateCNPJ } from '../../../../utils/validateCNPJ';

interface CreditFormProps {
  companyID: string;
}

interface FormData {
  name: string;
  cpf: string;
  phone: string;
  email: string;
  personType: string;
  creditType: string;
  propertyID: string;
  propertyValue: string;
  address: string;
  opportunityValue: string;
  term: string;
  birthday: Date;
}

function CreditForm({ companyID }: CreditFormProps): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const handleOnMutationCompleted = useCallback(() => {
    toast.success('A requisição de crédito foi realizada com sucesso!');
    history.push('/obrigado');
  }, [history]);

  const handleOnMutationError = useCallback((error: ApolloError) => {
    toast.error(
      `Houve um erro ao consultar o banco de dados. Por favor, tente novamente mais tarde. ${error}`,
    );
  }, []);

  const [addContact, { loading }] = useMutation<
    AddRealEstateContactMutationResponse,
    AddRealEstateContactMutationVars
  >(ADD_REAL_ESTATE_CONTACT, {
    onCompleted: handleOnMutationCompleted,
    onError: handleOnMutationError,
  });

  const handleOnSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          address: Yup.string().required(
            'Você precisa fornecer o endereço do imóvel',
          ),
          cpf: Yup.string()
            .required('Você precisa fornecer o seu CPF/CNPJ')
            .test(
              'cpf',
              'Você precisa informar um CPF/CNPJ válido',
              (cpf) => validateCPF(cpf || '') || validateCNPJ(cpf || ''),
            ),
          birthday: Yup.date().required(
            'Você precisar informar o sua data de nascimento',
          ),
          creditType: Yup.string().required(
            'Você precisa selecionar o tipo de crédito',
          ),
          email: Yup.string()
            .required('Você precisa fornecer o seu email')
            .email('Insira um email válido'),
          name: Yup.string().required(
            'Você precisa fornecer o seu nome completo',
          ),
          opportunityValue: Yup.number()
            .typeError('Você precisa fornecer um valor numérico')
            .required(
              'Você precisa fornecer um valor pretendido para empréstimo',
            )
            .positive('Por favor, insira um valor positivo'),
          personType: Yup.string().required(
            'Você precisa selecionar o seu tipo de pessoa',
          ),
          phone: Yup.string().required('Você precisa inserir o seu telefone'),
          propertyID: Yup.string().required(
            'Você precisa selecionar o tipo do seu imóvel',
          ),
          propertyValue: Yup.number()
            .typeError('Você precisa fornecer um valor numérico')
            .required('Você precisa fornecer o valor da propriedade')
            .positive('Por favor, insira um valor positivo'),
          term: Yup.number()
            .typeError('Você precisa fornecer um valor numérico')
            .required('Você precisa fornecer o prazo')
            .min(0, 'O prazo mínimo é de 0')
            .max(420, 'O prazo máximo é de 420'),
        });
        await schema.validate(data, { abortEarly: false });
        const formattedData = {
          address: data.address,
          birthday: data.birthday,
          companyID,
          cpf: data.cpf,
          creditType: data.creditType,
          email: data.email,
          name: data.name,
          opportunityValue: parseFloat(data.opportunityValue),
          personType: data.personType,
          phone: data.phone,
          propertyID: data.propertyID,
          propertyValue: parseFloat(data.propertyValue),
          term: parseInt(data.term, 10),
        } as AddRealEstateContactMutationVars;
        await addContact({
          variables: formattedData,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        toast.warning(
          'Houve um erro ao processar os dados! Por favor, entre em contato com o suporte',
        );
      }
    },
    [addContact, companyID],
  );
  return (
    <FormContainer ref={formRef} onSubmit={handleOnSubmit}>
      <Fieldset>
        <legend>Informações Pessoais</legend>
        <Input name="name" label="Nome" />
        <InputDate name="birthday" label="Data de nascimento" />
        <CPFCNPJInputNumber name="cpf" label="CPF/CNPJ" mask="" />
        <InputNumber name="phone" label="Telefone" format="(##) # ####-####" />
        <Input name="email" label="Email" />
        <Select
          name="personType"
          label="Pessoa Física ou jurídica"
          options={personTypes}
        />
      </Fieldset>

      <Fieldset>
        <legend>Informações do Imóvel</legend>
        <Select
          name="creditType"
          label="Tipo de financiamento"
          options={financingTypes}
        />
        <Select
          name="propertyID"
          label="Tipo de imóvel"
          options={propertyTypes}
        />
        <Input name="address" label="Endereço do imóvel" />
        <InputNumber
          name="propertyValue"
          label="Valor do imóvel"
          prefix="R$ "
        />
        <InputNumber
          name="opportunityValue"
          label="Valor pretendido para empréstimo (R$)"
          prefix="R$ "
        />
        <InputNumber name="term" label="Prazo (0 a 420 meses)" />
      </Fieldset>
      <TermsAndConditionsMessage ButtonName="Quero Crédito" />
      <Button icon={CheckCircleOutline} type="submit" disabled={loading}>
        {loading ? 'Solicitando Crédito' : 'Quero Crédito'}
      </Button>
    </FormContainer>
  );
}

export default CreditForm;
