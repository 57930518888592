import { CheckCircle } from '@material-ui/icons';
import React from 'react';

import { Container, Content } from './style';

function Obrigado(): JSX.Element {
  return (
    <Container>
      <Content>
        <h2>Muito Obrigado!</h2>
        <h4>A sua solicitação de crédito foi realizada com sucesso.</h4>
        <CheckCircle />
      </Content>
    </Container>
  );
}

export default Obrigado;
