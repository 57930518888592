import { useField } from '@unform/core';
import React, { useState, useCallback, useEffect, useRef } from 'react';

import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import { TextFieldCustomized } from '../../Input/style';

interface IInputNumberProps extends NumberFormatProps {
  name: string;
}

function CPFCNPJInputNumber({ name, ...rest }: IInputNumberProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [format, setFormat] = useState<string>('###.###.###-###');
  const [value, setValue] = useState<string>('');
  const handleOnChange = useCallback(
    ({ value: inputValue }: NumberFormatValues) => {
      setFormat(
        inputValue.length <= 11 ? '###.###.###-###' : '##.###.###/####-##',
      );
      setValue(inputValue);
    },
    [],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: () => value,
      setValue: (ref, someValue: string) => {
        // eslint-disable-next-line no-param-reassign
        ref.current.value = someValue;
        setValue(someValue);
      },
    });
  }, [fieldName, inputRef, registerField, value]);

  return (
    <NumberFormat
      customInput={TextFieldCustomized}
      onValueChange={handleOnChange}
      inputRef={inputRef}
      variant="filled"
      inputProps={{
        name: fieldName,
        value: defaultValue,
      }}
      error={!!error}
      helperText={error}
      fullWidth
      isNumericString
      thousandSeparator="."
      decimalSeparator=","
      format={format}
      label="CPF/CNPJ"
      {...rest}
    />
  );
}

export { CPFCNPJInputNumber };
