const clientSituationTypes = [
  {
    label: 'Assalariado',
    value: '171',
  },
  {
    label: 'Empresário',
    value: '173',
  },
  {
    label: 'Funcionário Público',
    value: '175',
  },
  {
    label: 'Aposentado',
    value: '177',
  },
  {
    label: 'Autônomo',
    value: '179',
  },
];

export default clientSituationTypes;
