import { CheckCircleOutline } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import InputDate from '../../../components/InputDate';
import { InputFile } from '../../../components/InputFile';
import InputNumber from '../../../components/InputNumber';
import { CPFCNPJInputNumber } from '../../../components/InputNumber/CPFCNPJInputNumber';
import Select from '../../../components/Select';
import { api } from '../../../services/api';
import { FormContainer } from '../../../Templates/Credit/style';
import getValidationErrors from '../../../utils/getValidationErrors';
import { validateCNPJ } from '../../../utils/validateCNPJ';
import validateCPF from '../../../utils/ValidateCPF';
import personTypes from '../../Vehicular/Form/CreditForm/SelectTypes/personTypes';
import { contractTypes } from './Types/contractTypes';

interface CreditFormProps {
  companyID: string;
}

export interface IPersonalCreditFormData {
  name: string;
  birthday: Date;
  cpf: string;
  phone: string;
  email: string;
  opportunityValue: number;
  personType: string;
  typeOfContract: number;
  CNH: File[];
  proofOfAddress: File[];
}

function CreditForm({ companyID }: CreditFormProps): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

  const handleOnSubmit = useCallback(
    async (data: IPersonalCreditFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          name: yup
            .string()
            .required('Você precisa fornecer o seu nome completo'),
          birthday: yup
            .date()
            .required('Você precisar informar o sua data de nascimento'),
          cpf: yup
            .string()
            .required('Você precisa fornecer o seu CPF/CNPJ')
            .test(
              'cpf',
              'Você precisa informar um CPF/CNPJ válido',
              (cpf) => validateCPF(cpf || '') || validateCNPJ(cpf || ''),
            ),
          phone: yup.string().required('Você precisa inserir o seu telefone'),
          email: yup
            .string()
            .required('Você precisa fornecer o seu email')
            .email('Insira um email válido'),
          personType: yup
            .string()
            .required('Você precisa selecionar o seu tipo de pessoa'),
          opportunityValue: yup
            .number()
            .typeError('Você precisa fornecer um valor numérico')
            .required(
              'Você precisa fornecer um valor pretendido para empréstimo',
            )
            .positive('Por favor, insira um valor positivo'),
          typeOfContract: yup
            .string()
            .required('Você precisa selecionar o seu tipo de pessoa'),
          CNH: yup
            .mixed()
            .test(
              'Arquivos',
              'Você precisa inserir uma foto da sua CNH',
              (value) => value.length,
            ),
          proofOfAddress: yup
            .mixed()
            .test(
              'Arquivos',
              'Você precisa inserir uma foto do seu comprovante de residência',
              (value) => value.length,
            ),
        });
        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('birthday', new Date(data.birthday).toISOString());
        formData.append('cpf', data.cpf);
        formData.append('phone', data.phone);
        formData.append('email', data.email);
        formData.append('opportunityValue', String(data.opportunityValue));
        formData.append('personType', data.personType);
        formData.append('typeOfContract', String(data.typeOfContract));
        formData.append('CNH', data.CNH[0]);
        formData.append('proofOfAddress', data.proofOfAddress[0]);
        formData.append('companyID', companyID);
        const config: AxiosRequestConfig = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        setIsRequestLoading(true);
        const response = await api.post('/deal/personal', formData, config);
        if (response.status === 200) {
          const successMessage =
            'A requisição do crédito pessoal foi realizada com sucesso!';
          toast.success(successMessage);
          setIsRequestLoading(false);
          history.push(`/obrigado`);
        }
      } catch (error) {
        setIsRequestLoading(false);
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        if (axios.isAxiosError(error)) {
          const axiosError: AxiosError = error;
          toast.warning(axiosError.message);
          return;
        }
        const errorMessage =
          'Houve um erro ao processar os dados! Por favor, entre em contato com o suporte';
        toast.warning(errorMessage);
      }
    },
    [companyID, history],
  );
  return (
    <FormContainer onSubmit={handleOnSubmit} ref={formRef}>
      <Input name="name" label="Nome" fullWidth />
      <InputDate name="birthday" label="Data de Nascimento" />
      <CPFCNPJInputNumber name="cpf" />
      <InputNumber name="phone" label="Telefone" format="(##) # ####-####" />
      <Input name="email" label="E-mail" />
      <Select
        name="personType"
        label="Pessoa Física ou Jurídica"
        options={personTypes}
      />
      <InputNumber
        name="opportunityValue"
        label="Valor do crédito pessoal"
        prefix="R$ "
      />
      <Select
        name="typeOfContract"
        label="Tipo de Contrato"
        options={contractTypes}
      />
      <InputFile name="CNH" label="CNH" accept="image/*" multiple={false} />

      <InputFile
        name="proofOfAddress"
        label="Comprovante de Residência"
        accept="image/*"
        multiple={false}
      />
      <Button
        icon={CheckCircleOutline}
        type="submit"
        disabled={isRequestLoading}
      >
        {isRequestLoading ? 'Realizando Requisição' : 'Quero Crédito'}
      </Button>
    </FormContainer>
  );
}

export { CreditForm };
