export const genderTypes = [
  {
    value: '329',
    label: 'Masculino',
  },
  {
    value: '331',
    label: 'Feminino',
  },
];
