import { ApolloError, useMutation } from '@apollo/client';
import { CheckCircleOutline } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import InputDate from '../../../../components/InputDate';
import InputNumber from '../../../../components/InputNumber';
import { CPFCNPJInputNumber } from '../../../../components/InputNumber/CPFCNPJInputNumber';
import Select from '../../../../components/Select';
import TermsAndConditionsMessage from '../../../../components/TermsAndConditionsMessage';
import ADD_VEHICULAR_CREDIT_CONTACT, {
  addVehicularCreditContactData,
  addVehicularCreditContactVars,
} from '../../../../graphql/mutations/addVehicularCreditContact';
import { Fieldset, FormContainer } from '../../../../Templates/Credit/style';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { validateCNPJ } from '../../../../utils/validateCNPJ';
import validateCPF from '../../../../utils/ValidateCPF';
import clientSituationTypes from './SelectTypes/clientSituation';
import creditTypes from './SelectTypes/creditType';
import personTypes from './SelectTypes/personTypes';

interface CreditFormProps {
  companyID: string;
}

interface FormData {
  email: string;
  phone: string;
  name: string;
  cpf: string;
  birthday: Date;
  vehicularCreditType: string;
  clientSituation: string;
  contactMonthlyIncome: string;
  address: string;
  vehicleName: string;
  vehicleModel: string;
  vehicleManufacturedDate: string;
  vehicleValue: string;
  vehicleTargetValue: string;
  personType: string;
}

function CreditForm({ companyID }: CreditFormProps): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const handleOnMutationCompleted = useCallback(() => {
    toast.success(
      'A requisição de crédito veícular foi realizada com sucesso!',
    );
    history.push('/obrigado');
  }, [history]);

  const handleOnMutationError = useCallback(({ message }: ApolloError) => {
    toast.error(
      `Houve um erro ao consultar o banco de dados. Por favor, tente novamente mais tarde. ${message}`,
    );
  }, []);

  const [addVehicularContact, { loading }] = useMutation<
    addVehicularCreditContactData,
    addVehicularCreditContactVars
  >(ADD_VEHICULAR_CREDIT_CONTACT, {
    onCompleted: handleOnMutationCompleted,
    onError: handleOnMutationError,
  });

  const handleOnSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(
            'Você precisar informar o seu nome completo',
          ),
          cpf: Yup.string()
            .required('Você precisar informar o seu CPF/CNPJ')
            .test(
              'cpf',
              'Você precisa informar um CPF/CNPJ válido',
              (cpf) => validateCPF(cpf || '') || validateCNPJ(cpf || ''),
            ),
          birthday: Yup.date().required(
            'Você precisar informar o sua data de nascimento',
          ),
          phone: Yup.string().required('Você precisar informar o seu telefone'),
          email: Yup.string()
            .required('Você precisar informar o seu email')
            .email('Você precisa inserir um email válido'),
          vehicularCreditType: Yup.string().required(
            'Você precisa selecionar o tipo de crédito veícular',
          ),
          clientSituation: Yup.string().required(
            'Você precisa selecionar a sua situação atual',
          ),
          personType: Yup.string().required(
            'Você precisa selecionar o seu tipo de pessoa',
          ),
          contactMonthlyIncome: Yup.number()
            .typeError('Você precisar informar um valor numérico')
            .required('Você precisar informar a sua renda mensal')
            .positive('Por favor, insira um valor positivo.'),
          address: Yup.string().required('Você precisa fornecer o endereço'),
          vehicleName: Yup.string().required(
            'Você precisa informar o nome do veículo',
          ),
          vehicleModel: Yup.string().required(
            'Você precisa informar o modelo do veículo',
          ),
          vehicleManufacturedDate: Yup.number()
            .typeError('Você precisar informar um valor numérico')
            .required(
              'Você precisar informar o ano de fabricação do seu veículo',
            )
            .positive('Por favor, insira um valor positivo'),
          vehicleValue: Yup.number()
            .typeError('Você precisa informar um valor numérico')
            .required('Você precisa informar o valor do seu veículo')
            .positive('É necessário informar um valor maior que zero'),
          vehicleTargetValue: Yup.number()
            .typeError('Você precisa informar um valor numérico')
            .required('Você precisa informar o valor pretendido do seu veículo')
            .positive('É necessário informar um valor maior que zero'),
        });
        await schema.validate(data, { abortEarly: false });
        const formattedData = {
          address: data.address,
          birthday: data.birthday,
          clientSituation: data.clientSituation,
          cpf: data.cpf,
          companyID,
          contactMonthlyIncome: parseFloat(data.contactMonthlyIncome),
          email: data.email,
          name: data.name,
          phone: data.phone,
          vehicleManufacturedDate: data.vehicleManufacturedDate,
          vehicleModel: data.vehicleModel,
          vehicleName: data.vehicleName,
          vehicleTargetValue: parseFloat(data.vehicleTargetValue),
          vehicleValue: parseFloat(data.vehicleValue),
          vehicularCreditType: data.vehicularCreditType,
          personType: data.personType,
          opportunityValue: parseFloat(data.vehicleTargetValue),
        };
        await addVehicularContact({ variables: formattedData });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        toast.warning(
          'Houve um erro ao processar os dados! Por favor, entre em contato com o suporte',
        );
      }
    },
    [addVehicularContact, companyID],
  );
  return (
    <FormContainer ref={formRef} onSubmit={handleOnSubmit}>
      <Fieldset>
        <legend>Informações Pessoais</legend>
        <Input name="name" label="Nome completo" />
        <InputDate name="birthday" label="Data de nascimento" />
        <CPFCNPJInputNumber name="cpf" />
        <InputNumber name="phone" label="Telefone" format="(##) # ####-####" />
        <Input name="email" label="Email" />
        <Select
          name="personType"
          label="Pessoa Física ou jurídica"
          options={personTypes}
        />
      </Fieldset>
      <Fieldset>
        <legend>Informações do veículo</legend>
        <Select
          name="vehicularCreditType"
          label="Tipo de crédito"
          options={creditTypes}
        />
        <Select
          name="clientSituation"
          label="Situação do cliente"
          options={clientSituationTypes}
        />

        <InputNumber
          name="contactMonthlyIncome"
          label="Renda Mensal"
          prefix="R$ "
        />
        <Input name="address" label="Endereço" />
        <Input name="vehicleName" label="Nome do veículo" />
        <Input name="vehicleModel" label="Modelo do veículo" />
        <InputNumber
          name="vehicleManufacturedDate"
          label="Ano de fabricação"
          format="####"
        />
        <InputNumber
          name="vehicleValue"
          label="Valor do veículo"
          prefix="R$ "
        />
        <InputNumber
          name="vehicleTargetValue"
          label="Valor pretendido do veículo"
          prefix="R$ "
        />
      </Fieldset>
      <TermsAndConditionsMessage ButtonName="Quero Crédito" />
      <Button icon={CheckCircleOutline} type="submit" disabled={loading}>
        {loading ? 'Realizando Requisição' : 'Quero Crédito'}
      </Button>
    </FormContainer>
  );
}

export default CreditForm;
