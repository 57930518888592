import { gql } from '@apollo/client';

export interface GetCompanyInfoQueryVars {
  id: string;
}

export interface ICompany {
  name: string;
  isSuspended: boolean;
}

export interface GetCompanyInfoQueryData {
  getCompanyInfo: ICompany;
}

const getCompanyInfoQuery = gql`
  query getCompanyInfo($id: String!) {
    getCompanyInfo(data: { id: $id }) {
      name
      isSuspended
    }
  }
`;

export default getCompanyInfoQuery;
