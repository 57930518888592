import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.button`
  width: 100%;
  height: 48px;
  min-height: 48px;

  display: grid;
  align-items: center;
  justify-items: start;
  grid-auto-flow: column;
  grid-template-columns: 1fr min-content;

  background-color: ${colors.primary.normal.first};

  border: 1px solid ${colors.primary.normal.first};
  border-radius: 3px;
  padding: 0 1rem;
  cursor: pointer;

  color: ${colors.text.first};

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.primary.normal.second};
  }
`;
