export const maritalStatusTypes = [
  {
    value: '387',
    label: 'Solteiro',
  },
  {
    value: '389',
    label: 'Casado',
  },
  {
    value: '391',
    label: 'Desquitado',
  },
  {
    value: '393',
    label: 'Divorciado',
  },
  {
    value: '395',
    label: 'Viúvo',
  },
  {
    value: '397',
    label: 'União estável',
  },
];
