import { ApolloError, useMutation } from '@apollo/client';
import { CheckCircleOutline } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import InputDate from '../../../../../components/InputDate';
import InputNumber from '../../../../../components/InputNumber';
import { CPFCNPJInputNumber } from '../../../../../components/InputNumber/CPFCNPJInputNumber';
import Select from '../../../../../components/Select';
import { createExchangeDealForPhysicalPersonMutation } from '../../../../../graphql/mutations/createExchangeDealForPhysicalPerson';
import { FormContainer } from '../../../../../Templates/Credit/style';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import validateCPF from '../../../../../utils/ValidateCPF';
import { useExchangeCredit } from '../../../hooks/ExchangeCredit';
import { brazilianStatesTypes } from './types/brazilianStatesTypes';
import { genderTypes } from './types/genderTypes';
import { maritalStatusTypes } from './types/maritalStatusTypes';
import { relevanteRoleTypes } from './types/relevantRoleTypes';
import { transactionTypes } from './types/transactionTypes';

interface IIndividualFormData {
  name: string;
  birthday: Date;
  cpf: string;
  email: string;
  opportunityValue: number;
  transactionType: string;
  interestComment: string;
  rg: string;
  rgIssueDate: Date;
  rgIssuingAgency: string;
  naturalness: string;
  nationality: string;
  gender: string;
  profession: string;
  monthlyIncome: number;
  homeAddress: string;
  district: string;
  city: string;
  UF: string;
  zipCode: string;
  maritalStatus: string;
  phone: string;
  cellPhone: string;
  doesTheCustomerHaveAnyRelevantRole: string;
}

interface IIndividualFormProps {
  companyID: string;
}

function IndividualForm({ companyID }: IIndividualFormProps): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleOnCreatePartnerExchangeDealForPhysicalPersonCompleted = useCallback(() => {
    const successMessage = 'Requisição de câmbio realizada com sucesso';
    toast.success(successMessage);
    history.push(`/obrigado`);
  }, [history]);

  const handleOnCreatePartnerExchangeDealForPhysicalPersonMutationError = useCallback(
    ({ message }: ApolloError) => {
      const errorMessage = `Não foi possível realizar a solicitação de crédito: ${message}`;
      toast.warning(errorMessage);
    },
    [],
  );

  const [
    createExchangeDealForPhysicalPerson,
    { loading: isRequestLoading },
  ] = useMutation(createExchangeDealForPhysicalPersonMutation, {
    onCompleted: handleOnCreatePartnerExchangeDealForPhysicalPersonCompleted,
    onError: handleOnCreatePartnerExchangeDealForPhysicalPersonMutationError,
  });

  const { personType } = useExchangeCredit();

  const handleOnSubmit = useCallback(
    async (data: IIndividualFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          name: yup
            .string()
            .required('Você precisa informar o seu nome completo'),
          birthday: yup
            .date()
            .required('Você precisar informar o sua data de nascimento'),
          cpf: yup
            .string()
            .required('Você precisa fornecer o seu CPF/CNPJ')
            .test('cpf', 'Você precisa informar um CPF válido', (cpf) =>
              validateCPF(cpf || ''),
            ),
          email: yup
            .string()
            .required('Você precisa fornecer o seu email')
            .email('Insira um email válido'),
          opportunityValue: yup
            .number()
            .typeError('Você precisa fornecer um valor numérico')
            .required(
              'Você precisa fornecer um valor pretendido para empréstimo',
            )
            .positive('Por favor, insira um valor positivo'),
          transactionType: yup
            .string()
            .required('Você precisa selecionar o tipo de transação'),
          interestComment: yup
            .string()
            .required(
              'Por favor, informe o interessem em realizar operações de câmbio',
            ),
          rg: yup.string().required('Você precisa inserir o seu RG'),
          rgIssueDate: yup
            .date()
            .required('Você precisar informar a data de expedição do seu RG'),
          rgIssuingAgency: yup
            .string()
            .required('Você precisa informar o órgão expedidor do seu RG'),
          naturalness: yup
            .string()
            .required('Você precisa informar a sua naturalidade'),
          nationality: yup
            .string()
            .required('Você precisa informar a sua nacionalidade'),
          gender: yup.string().required('Você precisa informar o seu sexo'),
          profession: yup
            .string()
            .required('Você precisa informar a sua profissão'),
          monthlyIncome: yup
            .number()
            .typeError('Você precisa fornecer um valor numérico')
            .required('Você precisa informar a sua renda mensal')
            .positive('Por favor, insira um valor positivo'),
          homeAddress: yup
            .string()
            .required('Você precisa inserir o seu endereço'),
          district: yup.string().required('Você precisa informar o seu bairro'),
          city: yup
            .string()
            .required('Você precisa informar o nome da sua cidade'),
          UF: yup.string().required('Você precisa informar a UF do seu estado'),
          zipCode: yup
            .string()
            .required('Vcê precisa inserir o CEP do seu endereço'),
          maritalStatus: yup
            .string()
            .required('VocÊ precisa informar o seu estado civil'),
          phone: yup
            .string()
            .required('Você precisa informar o numero do seu telefone fixo'),
          cellPhone: yup
            .string()
            .required('Você precisa informar o numero do seu celular'),
          doesTheCustomerHaveAnyRelevantRole: yup
            .string()
            .required(
              'Você exerce ou exerceu no últimos cinco anos algum cargo, emprego ou função pública relevante?',
            ),
        });
        await schema.validate(data, { abortEarly: false });
        await createExchangeDealForPhysicalPerson({
          variables: {
            ...data,
            companyID,
            personType,
            monthlyIncome: parseFloat(String(data.monthlyIncome)),
            opportunityValue: parseFloat(String(data.opportunityValue)),
          },
        });
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        const errorMessage =
          'Houve um erro ao processar os dados! Por favor, entre em contato com o suporte';
        toast.error(errorMessage);
      }
    },
    [companyID, createExchangeDealForPhysicalPerson, personType],
  );
  return (
    <FormContainer ref={formRef} onSubmit={handleOnSubmit}>
      <Input name="name" label="Nome" fullWidth />
      <InputDate name="birthday" label="Data de Nascimento" />
      <CPFCNPJInputNumber name="cpf" label="CPF" />
      <Input name="email" label="E-mail" />
      <InputNumber
        name="opportunityValue"
        label="Valor do crédito pessoal"
        prefix="R$ "
      />
      <Select
        name="transactionType"
        options={transactionTypes}
        label="Selecione o tipo de transação"
      />
      <Input
        name="interestComment"
        label="Qual o interesse em realizar operações de câmbio"
      />
      <InputNumber name="rg" label="RG" />
      <InputDate name="rgIssueDate" label="Data de expedição do RG" />
      <Input name="rgIssuingAgency" label="Órgão expedidor do documento" />
      <Input name="naturalness" label="Naturalidade" />
      <Input name="nationality" label="Nacionalidade" />
      <Select name="gender" options={genderTypes} label="Sexo" />
      <Input name="profession" label="Profissão" />
      <InputNumber name="monthlyIncome" label="Renda Mensal" />
      <Input name="homeAddress" label="Endereço Residencial" />
      <Input name="district" label="Bairro" />
      <Input name="city" label="Cidade" />
      <Select name="UF" options={brazilianStatesTypes} label="UF" />
      <InputNumber name="zipCode" label="CEP" format="#####-###" />
      <Select
        name="maritalStatus"
        options={maritalStatusTypes}
        label="Estado Civil"
      />
      <InputNumber name="phone" label="Telefone Fixo" format="(##) ####-####" />
      <InputNumber
        name="cellPhone"
        label="Telefone celular"
        format="(##) 9####-####"
      />
      <Select
        name="doesTheCustomerHaveAnyRelevantRole"
        options={relevanteRoleTypes}
        label="Exerceu alguma função relevante nos últimos cinco anos?"
      />
      <Button
        icon={CheckCircleOutline}
        type="submit"
        disabled={isRequestLoading}
      >
        {isRequestLoading ? 'Realizando Requisição' : 'Quero Crédito'}
      </Button>
    </FormContainer>
  );
}

export { IndividualForm };
