import React, { ReactNode } from 'react';

import { ApolloProvider } from '@apollo/client';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import client from '../services/client';
import { CompanyProvider } from './Company';

interface AppProviderProps {
  children: ReactNode;
}

function AppProvider({ children }: AppProviderProps): JSX.Element {
  return (
    <ApolloProvider client={client}>
      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
        <CompanyProvider>{children}</CompanyProvider>
      </MuiPickersUtilsProvider>
    </ApolloProvider>
  );
}

export default AppProvider;
