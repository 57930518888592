import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCompany } from '../../hooks/Company';
import Template from '../../Templates/Credit';
import { ParamsProps } from '../RealEstate/dtos';
import { CreditForm } from './form';

function Consigned(): JSX.Element {
  const { id: companyID } = useParams<ParamsProps>();
  const { company, getCompanyInfo } = useCompany();

  useEffect(() => {
    getCompanyInfo(companyID);
  }, [companyID, getCompanyInfo]);
  return (
    <Template company={company} creditType="consignado">
      <CreditForm companyID={companyID} />
    </Template>
  );
}

export { Consigned };
