import React, { ButtonHTMLAttributes } from 'react';

import { SvgIconProps } from '@material-ui/core';
import { Container } from './style';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  // eslint-disable-next-line react/require-default-props
  icon?: React.ComponentType<SvgIconProps>;
}

function Button({ icon: Icon, children, ...rest }: ButtonProps): JSX.Element {
  return (
    <Container {...rest}>
      {children}
      {!!Icon && <Icon />}
    </Container>
  );
}

export default Button;
