const personTypes = [
  {
    label: 'Pessoa Física',
    value: '45',
  },
  {
    label: 'Pessoa Jurídica',
    value: '47',
  },
];

export default personTypes;
