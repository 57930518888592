export const brazilianStatesTypes = [
  {
    value: '333',
    label: 'AC',
  },
  {
    value: '335',
    label: 'AL',
  },
  {
    value: '337',
    label: 'AP',
  },
  {
    value: '339',
    label: 'AM',
  },
  {
    value: '341',
    label: 'BA',
  },
  {
    value: '343',
    label: 'CE',
  },
  {
    value: '345',
    label: 'DF',
  },
  {
    value: '347',
    label: 'ES',
  },
  {
    value: '349',
    label: 'GO',
  },
  {
    value: '351',
    label: 'MA',
  },
  {
    value: '353',
    label: 'MT',
  },
  {
    value: '355',
    label: 'MS',
  },
  {
    value: '357',
    label: 'MG',
  },
  {
    value: '359',
    label: 'PA',
  },
  {
    value: '361',
    label: 'PB',
  },
  {
    value: '363',
    label: 'PR',
  },
  {
    value: '365',
    label: 'PE',
  },
  {
    value: '367',
    label: 'PI',
  },
  {
    value: '369',
    label: 'RJ',
  },
  {
    value: '371',
    label: 'RN',
  },
  {
    value: '373',
    label: 'RS',
  },
  {
    value: '375',
    label: 'RO',
  },
  {
    value: '377',
    label: 'RR',
  },
  {
    value: '379',
    label: 'SC',
  },
  {
    value: '381',
    label: 'SP',
  },
  {
    value: '383',
    label: 'SE',
  },
  {
    value: '385',
    label: 'TO',
  },
];
