import React from 'react';
import { Link } from 'react-router-dom';

import { Message } from './style';

interface TermsAndConditionsProps {
  ButtonName: string;
}

function TermsAndConditionsMessage({
  ButtonName,
}: TermsAndConditionsProps): JSX.Element {
  return (
    <Message>
      Clicando no botão {ButtonName}, eu concordo com as{' '}
      <Link to={{ pathname: '/sobre/privacidade' }}>
        Política de Privacidade
      </Link>{' '}
      e os <Link to={{ pathname: '/sobre/termos' }}>Termos de Serviço</Link> da
      plataforma.
    </Message>
  );
}

export default TermsAndConditionsMessage;
