import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from 'react';
import personTypes from '../../RealEstate/Form/CreditForm/SelectTypes/personTypes';
import { IndividualForm } from '../components/forms/IndividualForm';
import { LegalEntitiesForm } from '../components/forms/LegalEntitiesForm';

interface IExchangeCreditContextData {
  personType: string;
  setPersonType(personType: string): void;
  currentForm: ReactNode;
  setCurrentForm(form: ReactNode): void;
  companyID: string;
  setCompanyID(id: string): void;
}

const exchangeCreditContext = createContext<IExchangeCreditContextData>(
  {} as IExchangeCreditContextData,
);

interface IExchangeCreditProviderProps {
  children: ReactNode;
}

export function ExchangeCreditProvider({
  children,
}: IExchangeCreditProviderProps): JSX.Element {
  const [personType, setPersonType] = useState<string>(personTypes[0].value);
  const [currentForm, setCurrentForm] = useState<ReactNode>(<></>);
  const [companyID, setCompanyID] = useState<string>('');
  useEffect(() => {
    setCurrentForm(
      personType === '45' ? (
        <IndividualForm companyID={companyID} />
      ) : (
        <LegalEntitiesForm companyID={companyID} />
      ),
    );
  }, [companyID, personType]);
  return (
    <exchangeCreditContext.Provider
      value={{
        personType,
        setPersonType,
        currentForm,
        setCurrentForm,
        companyID,
        setCompanyID,
      }}
    >
      {children}
    </exchangeCreditContext.Provider>
  );
}

export function useExchangeCredit(): IExchangeCreditContextData {
  const context = useContext(exchangeCreditContext);
  if (!context) {
    throw new Error(
      'useExchangeCredit must be used within a ExchangeCreditProvider',
    );
  }
  return context;
}
