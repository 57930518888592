import { createMuiTheme, withStyles } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import { KeyboardDatePicker } from '@material-ui/pickers';
import colors from '../../styles/colors';

export const KeyboardDatePickerCustomized = withStyles({
  root: {
    '& input': {
      color: colors.text.first,
    },
    '& svg': {
      color: colors.text.first,
    },
    '& label': {
      color: colors.text.second,
    },
    '& label.Mui-focused, label.MuiFormLabel-filled': {
      color: colors.primary.normal.first,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: colors.text.second,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.primary.normal.first,
    },

    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: 'rgba(70, 161, 132, 0.06)',
    },
    '& .Mui-error': {
      color: colors.error.second,
    },
  },
})(KeyboardDatePicker);

export const MuiPickerTheme = createMuiTheme({
  palette: {
    primary: teal,
  },
});
