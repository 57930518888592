import { gql } from '@apollo/client';

export interface addVehicularCreditContactVars {
  address: string;
  birthday: Date;
  clientSituation: string;
  companyID: string;
  contactMonthlyIncome: number;
  cpf: string;
  email: string;
  name: string;
  phone: string;
  vehicleManufacturedDate: string;
  vehicleModel: string;
  vehicleName: string;
  vehicleTargetValue: number;
  vehicleValue: number;
  vehicularCreditType: string;
  personType: string;
}

export interface addVehicularCreditContactData {
  TITLE: string;
}

const ADD_VEHICULAR_CREDIT_CONTACT = gql`
  mutation addVehicularCreditContact(
    $address: String
    $birthday: DateTime!
    $clientSituation: String!
    $cnpj: String
    $companyID: String!
    $contactMonthlyIncome: Float!
    $cpf: String
    $email: String!
    $name: String!
    $opportunityValue: Float
    $phone: String
    $personType: String!
    $vehicleManufacturedDate: String!
    $vehicleModel: String!
    $vehicleName: String!
    $vehicleTargetValue: Float!
    $vehicleValue: Float!
    $vehicularCreditType: String!
  ) {
    addVehicularCreditContact(
      data: {
        address: $address
        birthday: $birthday
        clientSituation: $clientSituation
        cnpj: $cnpj
        companyID: $companyID
        contactMonthlyIncome: $contactMonthlyIncome
        cpf: $cpf
        email: $email
        name: $name
        opportunityValue: $opportunityValue
        phone: $phone
        vehicleManufacturedDate: $vehicleManufacturedDate
        vehicleModel: $vehicleModel
        vehicleName: $vehicleName
        vehicleTargetValue: $vehicleTargetValue
        vehicleValue: $vehicleValue
        vehicularCreditType: $vehicularCreditType
        personType: $personType
      }
    ) {
      TITLE
    }
  }
`;

export default ADD_VEHICULAR_CREDIT_CONTACT;
