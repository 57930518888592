import { TextField, withStyles } from '@material-ui/core';
import colors from '../../styles/colors';

export const SelectInputCustomized = withStyles({
  root: {
    '& .MuiFilledInput-input': {
      color: colors.text.first,
    },
    '& label, svg': {
      color: colors.text.second,
    },
    '& label.Mui-focused, label.MuiFormLabel-filled': {
      color: colors.primary.normal.first,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: colors.text.second,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: colors.primary.normal.first,
    },

    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: 'rgba(70, 161, 132, 0.06)',
    },
    '& .Mui-error': {
      color: colors.error.second,
    },
  },
})(TextField);
