import styled from 'styled-components';
import { Form } from '@unform/web';
import colors from '../../styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  align-content: center;
  justify-content: center;

  padding: 1rem 0%;

  background-color: ${colors.secondary.darker.first};
`;

export const Header = styled.header`
  width: 100%;
  height: 100%;

  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;

  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;

  gap: 1rem;

  color: ${colors.text.first};
  text-align: center;
`;

export const Section = styled.section`
  width: 100%;
  height: 100%;
  display: grid;
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const FormContainer = styled(Form)`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 1rem;

  padding: 1rem 0;
`;

export const Fieldset = styled.section`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  & > legend {
    color: ${colors.text.first};
    align-self: flex-start;
  }
`;
