const creditTypes = [
  {
    label: 'Refin',
    value: '167',
  },
  {
    label: 'Aquisição',
    value: '169',
  },
];

export default creditTypes;
