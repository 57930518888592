const financingTypes = [
  {
    label: 'Financiamento',
    value: '67',
  },
  {
    label: 'Portabilidade',
    value: '69',
  },
  {
    label: 'Home Equity',
    value: '71',
  },
];

export default financingTypes;
