import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;

  background-color: ${colors.background.first};
  color: ${colors.text.first};

  & > svg {
    width: 50px;
    height: 50px;
    color: ${colors.warning.second};
  }
`;
