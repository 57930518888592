import { MenuItem } from '@material-ui/core';
import React, { ChangeEvent, useCallback } from 'react';
import { SelectInputCustomized } from '../../../components/Select/style';
import personTypes from '../../RealEstate/Form/CreditForm/SelectTypes/personTypes';
import { useExchangeCredit } from '../hooks/ExchangeCredit';
import { SelectContainer } from './style';

function ExchangeForm(): JSX.Element {
  const {
    personType: currentPersonType,
    setPersonType,
    currentForm,
  } = useExchangeCredit();
  const handleOnSelectChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedPersonType = event.target.value;
      setPersonType(selectedPersonType);
    },
    [setPersonType],
  );
  return (
    <>
      <SelectContainer>
        <SelectInputCustomized
          value={currentPersonType}
          select
          variant="filled"
          label="Tipo de Pessoa"
          onChange={handleOnSelectChange}
        >
          {personTypes.map((personType) => (
            <MenuItem value={personType.value}>{personType.label}</MenuItem>
          ))}
        </SelectInputCustomized>
      </SelectContainer>
      {currentForm}
    </>
  );
}

export { ExchangeForm };
