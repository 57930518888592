import { gql } from '@apollo/client';

export interface IBitrixDeal {
  ID: number;
  TITLE: string;
  STAGE_ID: string;
  CURRENCY_ID: string;
  OPPORTUNITY: number;
  COMPANY_ID: number;
  CONTACT_ID: number;
  DATE_CREATE: string;
  DATE_MODIFY: string;
  COMMENTS: string;
  ADDITIONAL_INFO: string;
}

export interface ICreateExchangeDealForPhysicalPersonMutationVars {
  companyID: string;
  email: string;
  personType: string;
  opportunityValue: number;
  name: string;
  cpf: string;
  interestComment: string;
  birthday: Date;
  monthlyIncome: number;
  rg: string;
  rgIssueDate: Date;
  rgIssuingAgency: string;
  naturalness: string;
  nationality: string;
  gender: string;
  profession: string;
  homeAddress: string;
  district: string;
  city: string;
  UF: string;
  zipCode: string;
  maritalStatus: string;
  phone: string;
  cellPhone: string;
  doesTheCustomerHaveAnyRelevantRole: string;
}

export interface ICreateExchangeDealForPhysicalPersonMutationResponse {
  createExchangeDealForPhysicalPerson: IBitrixDeal;
}

export const createExchangeDealForPhysicalPersonMutation = gql`
  mutation createExchangeDealForPhysicalPerson(
    $companyID: String!
    $UF: String!
    $birthday: DateTime!
    $cellPhone: String!
    $city: String!
    $cpf: String!
    $district: String!
    $doesTheCustomerHaveAnyRelevantRole: String!
    $email: String!
    $gender: String!
    $homeAddress: String!
    $interestComment: String!
    $maritalStatus: String!
    $monthlyIncome: Float!
    $name: String!
    $nationality: String!
    $naturalness: String!
    $opportunityValue: Float!
    $personType: String!
    $phone: String!
    $profession: String!
    $rg: String!
    $rgIssueDate: DateTime!
    $rgIssuingAgency: String!
    $zipCode: String!
  ) {
    createExchangeDealForPhysicalPerson(
      data: {
        companyID: $companyID
        UF: $UF
        birthday: $birthday
        cellPhone: $cellPhone
        city: $city
        cpf: $cpf
        district: $district
        doesTheCustomerHaveAnyRelevantRole: $doesTheCustomerHaveAnyRelevantRole
        email: $email
        gender: $gender
        homeAddress: $homeAddress
        interestComment: $interestComment
        maritalStatus: $maritalStatus
        monthlyIncome: $monthlyIncome
        name: $name
        nationality: $nationality
        naturalness: $naturalness
        opportunityValue: $opportunityValue
        personType: $personType
        phone: $phone
        profession: $profession
        rg: $rg
        rgIssueDate: $rgIssueDate
        rgIssuingAgency: $rgIssuingAgency
        zipCode: $zipCode
      }
    ) {
      ID
    }
  }
`;
