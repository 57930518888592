import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useCompany } from '../../hooks/Company';
import Template from '../../Templates/Credit';
import { ParamsProps } from './dtos';
import CreditForm from './Form/CreditForm';

function RealEstate(): JSX.Element {
  const { id: companyID } = useParams<ParamsProps>();

  const { company, getCompanyInfo } = useCompany();
  useEffect(() => {
    getCompanyInfo(companyID);
  }, [companyID, getCompanyInfo]);
  return (
    <Template company={company} creditType="imobiliário">
      <CreditForm companyID={companyID} />
    </Template>
  );
}

export default RealEstate;
