import { CheckCircleOutline } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import React, { useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import InputDate from '../../../components/InputDate';
import { InputFile } from '../../../components/InputFile';
import InputNumber from '../../../components/InputNumber';
import { CPFCNPJInputNumber } from '../../../components/InputNumber/CPFCNPJInputNumber';
import Select from '../../../components/Select';
import { api } from '../../../services/api';
import { FormContainer } from '../../../Templates/Credit/style';
import getValidationErrors from '../../../utils/getValidationErrors';
import { validateCNPJ } from '../../../utils/validateCNPJ';
import validateCPF from '../../../utils/ValidateCPF';
import personTypes from '../../RealEstate/Form/CreditForm/SelectTypes/personTypes';

interface CreditFormProps {
  companyID: string;
}

interface IPrepaymentOfReceivablesFormData {
  name: string;
  birthday: Date;
  cpf: string;
  phone: string;
  email: string;
  opportunityValue: number;
  personType: string;
  socialContract: File[];
  associateCPF: File[];
  associateRG: File[];
  annualBilling: number;
  proofOfAddress: File[];
  proofOfBusinessAddress: File[];
}

function PrepaymentOfReceivablesForm({
  companyID,
}: CreditFormProps): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const history = useHistory();
  const handleOnSubmit = useCallback(
    async (data: IPrepaymentOfReceivablesFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          name: yup
            .string()
            .required('Você precisar informar o seu nome completo'),
          cpf: yup
            .string()
            .required('Você precisar informar o seu CPF/CNPJ')
            .test(
              'cpf',
              'Você precisa informar um CPF/CNPJ válido',
              (cpf) => validateCPF(cpf || '') || validateCNPJ(cpf || ''),
            ),
          birthday: yup
            .date()
            .required('Você precisar informar o sua data de nascimento'),
          phone: yup.string().required('Você precisar informar o seu telefone'),
          email: yup
            .string()
            .required('Você precisar informar o seu email')
            .email('Você precisa inserir um email válido'),
          personType: yup
            .string()
            .required('Você precisa selecionar o seu tipo de pessoa'),
          opportunityValue: yup
            .number()
            .typeError('Você precisa informar um valor numérico')
            .required(
              'Você precisa informar o valor da antecipação de recebíveis',
            )
            .positive('Por favor, insira um valor positivo'),
          socialContract: yup
            .mixed()
            .test(
              'Arquivos',
              'Você precisa inserir um arquivo de imagem com o contrato social',
              (value) => value.length,
            ),
          associateCPF: yup
            .mixed()
            .test(
              'Arquivos',
              'Você precisa inserir um arquivo de imagem com o CPF do seu sócio',
              (value) => value.length,
            ),
          associateRG: yup
            .mixed()
            .test(
              'Arquivos',
              'Você precisa inserir um arquivo de imagem com o RG do seu sócio',
              (value) => value.length,
            ),
          annualBilling: yup
            .number()
            .typeError('Você precisa informar um valor numérico')
            .required('Você precisa informar o seu faturamento anual')
            .positive('Por favor, insira um valor positivo'),
          proofOfAddress: yup
            .mixed()
            .test(
              'Arquivos',
              'Você precisa inserir um arquivo de imagem com o comprovante da sua residência',
              (value) => value.length,
            ),
          proofOfBusinessAddress: yup
            .mixed()
            .test(
              'Arquivos',
              'Você precisa inserir um arquivo de imagem com o comprovante de endereço comercial',
              (value) => value.length,
            ),
        });
        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();

        formData.append('companyID', companyID);
        formData.append('name', data.name);
        formData.append('birthday', new Date(data.birthday).toISOString());
        formData.append('cpf', data.cpf);
        formData.append('phone', data.phone);
        formData.append('email', data.email);
        formData.append('opportunityValue', String(data.opportunityValue));
        formData.append('personType', data.personType);
        formData.append('annualBilling', String(data.annualBilling));
        formData.append('socialContract', data.socialContract[0]);
        formData.append('associateCPF', data.associateCPF[0]);
        formData.append('associateRG', data.associateRG[0]);
        formData.append('proofOfAddress', data.proofOfAddress[0]);
        formData.append(
          'proofOfBusinessAddress',
          data.proofOfBusinessAddress[0],
        );

        const config: AxiosRequestConfig = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        setIsRequestLoading(true);
        const response = await api.post(
          '/deal/prepaymentOfReceivables',
          formData,
          config,
        );
        if (response.status === 200) {
          const successMessage =
            'A requisição da antecipação de recebíveis foi realizada com sucesso!';
          toast.success(successMessage);
          setIsRequestLoading(false);
          history.push(`/obrigado`);
        }
      } catch (error) {
        setIsRequestLoading(false);

        if (error instanceof yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        if (axios.isAxiosError(error)) {
          const axiosError: AxiosError = error;
          toast.warning(axiosError);
          return;
        }
        const warningMessage =
          'Houve um erro ao processar os dados! Por favor, entre em contato com o suporte';
        toast.warning(warningMessage);
      }
    },
    [companyID, history],
  );
  return (
    <FormContainer ref={formRef} onSubmit={handleOnSubmit}>
      <Input name="name" label="Nome" fullWidth />
      <InputDate name="birthday" label="Data de Nascimento" />
      <CPFCNPJInputNumber name="cpf" />
      <InputNumber name="phone" label="Telefone" format="(##) # ####-####" />
      <Input name="email" label="E-mail" />
      <Select
        name="personType"
        label="Pessoa Física ou Jurídica"
        options={personTypes}
      />
      <InputNumber
        name="opportunityValue"
        label="Valor da Antecipação de Recebível"
        prefix="R$ "
      />
      <InputNumber
        name="annualBilling"
        label="Faturamento Anual"
        prefix="R$ "
      />
      <InputFile name="proofOfAddress" label="Comprovante de Residência" />
      <InputFile
        name="proofOfBusinessAddress"
        label="Comprovante de Endereço Comercial"
      />
      <InputFile name="socialContract" label="Contrato Social" />
      <InputFile name="associateCPF" label="CPF do Sócio" />
      <InputFile name="associateRG" label="RG do Sócio" />
      <Button
        icon={CheckCircleOutline}
        type="submit"
        disabled={isRequestLoading}
      >
        {isRequestLoading ? 'Realizando Requisição' : 'Quero Crédito'}
      </Button>
    </FormContainer>
  );
}

export { PrepaymentOfReceivablesForm };
