import { useLazyQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import getCompanyInfoQuery, {
  GetCompanyInfoQueryData,
  GetCompanyInfoQueryVars,
  ICompany,
} from '../graphql/queries/getCompanyInfoQuery';

interface ICompanyContext {
  company: ICompany;
  setCompany(company: ICompany): void;
  getCompanyInfo(id: string): void;
  loading: boolean;
}

const companyContext = createContext<ICompanyContext>({} as ICompanyContext);

interface ICompanyProviderProps {
  children: ReactNode;
}

export function CompanyProvider({
  children,
}: ICompanyProviderProps): JSX.Element {
  const [company, setCompany] = useState<ICompany>({} as ICompany);
  const history = useHistory();

  const handleOnQueryError = useCallback(() => {
    toast.error(`Erro ao consultar a companhia`);
    history.push('/');
  }, [history]);

  const handleOnQueryCompleted = useCallback(
    ({ getCompanyInfo: fetchedCompanyInfo }: GetCompanyInfoQueryData) => {
      if(fetchedCompanyInfo.isSuspended === true) {
        toast.error('Empresa suspensa! Entre em contato com o suporte.');
        history.push('/');
      }
      setCompany(fetchedCompanyInfo);
    },
    [history],
  );
  const [getCompanyInfo, { loading }] = useLazyQuery<
    GetCompanyInfoQueryData,
    GetCompanyInfoQueryVars
  >(getCompanyInfoQuery, {
    onCompleted: handleOnQueryCompleted,
    onError: handleOnQueryError,
  });

  const queryCompanyInfo = useCallback(
    (id: string) => {
      getCompanyInfo({
        variables: {
          id,
        },
      });
    },
    [getCompanyInfo],
  );
  return (
    <companyContext.Provider
      value={{
        company,
        getCompanyInfo: queryCompanyInfo,
        setCompany,
        loading,
      }}
    >
      {children}
    </companyContext.Provider>
  );
}

export function useCompany(): ICompanyContext {
  const context = useContext(companyContext);
  if (!context) {
    throw new Error('You must use use within a CompanyProvider');
  }
  return context;
}
