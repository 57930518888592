export const contractTypes = [
  {
    label: 'Carteira Assinada',
    value: '612',
  },
  {
    label: 'Servidor Público',
    value: '614',
  },
];
