import { useField } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import { TextFieldCustomized } from '../Input/style';

interface InputNumberProps extends NumberFormatProps {
  name: string;
}

function InputNumber({ name, ...rest }: InputNumberProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [inputValue, setInputValue] = useState<string>();

  const handleOnChange = useCallback(({ value }: NumberFormatValues) => {
    setInputValue(value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => inputValue,
    });
  }, [fieldName, inputValue, registerField]);

  return (
    <NumberFormat
      customInput={TextFieldCustomized}
      onValueChange={handleOnChange}
      inputRef={inputRef}
      variant="filled"
      inputProps={{
        name: fieldName,
        value: defaultValue,
      }}
      error={!!error}
      helperText={error}
      fullWidth
      isNumericString
      thousandSeparator="."
      decimalSeparator=","
      {...rest}
    />
  );
}

export default InputNumber;
