import styled from 'styled-components';
import colors from '../../styles/colors';

export const Message = styled.p`
  color: ${colors.text.second};
  text-align: justify;
  font-size: 0.8rem;
  line-height: 1rem;

  transition: all 0.5s ease;

  & > a {
    text-decoration: none;
    color: ${colors.primary.normal.first};
  }

  & > a:hover {
    color: ${colors.primary.normal.second};
  }
`;
