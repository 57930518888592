import styled from 'styled-components';
import colors from '../../styles/colors';

interface IContainerProps {
  isThereError: boolean;
}

export const Container = styled.section<IContainerProps>`
  width: 100%;
  min-height: 3rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  padding-top: 1rem;
  &::after {
    width: 100%;
    height: 2px;
    content: '';
    background: ${(props) =>
      props.isThereError ? colors.error.first : 'rgba(0, 0, 0, 0.42)'};
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &:hover {
    &::after {
      width: 100%;
      height: 2px;
      content: '';
      background: ${(props) =>
        props.isThereError ? colors.error.second : colors.primary.normal.first};
    }
  }
`;

interface IContentProps {
  isDragActive: boolean;
}

export const Content = styled.article<IContentProps>`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  border: 1px dashed
    ${(props) =>
      props.isDragActive ? colors.primary.normal.first : 'transparent'};
  & > ul {
    display: flex;
    align-items: center;
    min-height: 100%;
    list-style-type: none;
    color: ${colors.text.second};
  }
  & > p {
    display: flex;
    align-items: center;
    height: 100%;
    color: ${colors.text.second};
  }
  transition: all 0.3s ease;
`;

interface IIconSectionProps {
  isThereError: boolean;
}

export const IconSection = styled.section<IIconSectionProps>`
  display: flex;
  height: 100%;
  padding: 0.5rem;
  background-color: ${(props) =>
    props.isThereError ? colors.error.second : colors.primary.normal.first};
  border-radius: 4px;
  color: #fff;
  & > svg {
    height: 2rem;
    width: auto;
  }
`;

export const LabelSection = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: max-content;
  color: ${colors.text.second};
  padding: 0;
  font-size: 12px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
`;

export const ErrorContainer = styled.section`
  width: 100%;
  display: flex;
  color: ${() => colors.error.second};
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;
