/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useField } from '@unform/core';
import React, { useRef, useEffect, useState, InputHTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';
import { HiFolder, HiFolderOpen } from 'react-icons/hi';

import {
  Container,
  Content,
  ErrorContainer,
  IconSection,
  LabelSection,
} from './style';

interface IInputFileProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
}

interface IInputRefProps extends HTMLInputElement {
  acceptedFiles: File[];
}

function InputFile({
  name,
  label,
  accept,
  ...rest
}: IInputFileProps): JSX.Element {
  const inputRef = useRef<IInputRefProps>(null);
  const { fieldName, defaultValue = [], error, registerField } = useField(name);

  const [acceptedFiles, setAcceptedFiles] = useState<File[]>(defaultValue);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop: (onDropAcceptedFiles) => {
      if (inputRef.current) {
        inputRef.current.acceptedFiles = onDropAcceptedFiles;
        setAcceptedFiles(onDropAcceptedFiles);
      }
    },
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: IInputRefProps) => {
        return ref.acceptedFiles || [];
      },
      clearValue: (ref: IInputRefProps) => {
        // eslint-disable-next-line no-param-reassign
        ref.acceptedFiles = [];
        setAcceptedFiles([]);
      },
      setValue: (ref: IInputRefProps, value) => {
        // eslint-disable-next-line no-param-reassign
        ref.acceptedFiles = value;
        setAcceptedFiles(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container
      {...getRootProps()}
      isThereError={!!error}
      onClick={() => inputRef.current?.click()}
    >
      <Content isDragActive={isDragActive}>
        <input {...getInputProps()} accept={accept} ref={inputRef} {...rest} />
        <IconSection isThereError={!!error}>
          {acceptedFiles.length === 0 ? <HiFolderOpen /> : <HiFolder />}
        </IconSection>
        {isDragActive ? (
          <p>Solte os arquivos aqui!</p>
        ) : (
          <>
            {acceptedFiles.length > 0 ? (
              <ul>
                {acceptedFiles.map((file) => (
                  <li key={file.name}>{file.name}</li>
                ))}
              </ul>
            ) : (
              <p>Arraste e solte os arquivos ou clique aqui</p>
            )}
          </>
        )}
      </Content>
      {!!label && <LabelSection>{label}</LabelSection>}
      {!!error && <ErrorContainer>{error}</ErrorContainer>}
    </Container>
  );
}

export { InputFile };
