export const transactionTypes = [
  {
    value: '457',
    label: 'Importações',
  },
  {
    value: '459',
    label: 'Exportações',
  },
  {
    value: '461',
    label: 'Ambos',
  },
];
