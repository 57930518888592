import { gql } from '@apollo/client';
import { IBitrixDeal } from './createExchangeDealForPhysicalPerson';

export interface ICreateExchangeDealForLegalPersonMutationVars {
  companyID: string;
  name: string;
  birthday: Date;
  personType: string;
  addressNumber: string;
  complement: string;
  district: string;
  opportunityValue: number;
  corporateName: string;
  fantasyName: string;
  companyAddress: string;
  phone: string;
  mainActivity: string;
  cnpj: string;
  city: string;
  uf: string;
  email: string;
}

export interface ICreateExchangeDealForLegalPersonMutationResponse {
  createExchangeDealForLegalPerson: IBitrixDeal;
}

export const createExchangeDealForLegalPersonMutation = gql`
  mutation createExchangeDealForLegalPerson(
    $addressNumber: String!
    $companyID: String!
    $birthday: DateTime!
    $city: String!
    $cnpj: String!
    $companyAddress: String!
    $complement: String!
    $corporateName: String!
    $district: String!
    $email: String!
    $fantasyName: String!
    $mainActivity: String!
    $name: String!
    $opportunityValue: Float!
    $personType: String!
    $phone: String!
    $uf: String!
  ) {
    createExchangeDealForLegalPerson(
      data: {
        companyID: $companyID
        addressNumber: $addressNumber
        birthday: $birthday
        city: $city
        cnpj: $cnpj
        companyAddress: $companyAddress
        complement: $complement
        corporateName: $corporateName
        district: $district
        email: $email
        fantasyName: $fantasyName
        mainActivity: $mainActivity
        name: $name
        opportunityValue: $opportunityValue
        personType: $personType
        phone: $phone
        uf: $uf
      }
    ) {
      ID
    }
  }
`;
