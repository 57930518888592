import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCompany } from '../../hooks/Company';
import Template from '../../Templates/Credit';
import { ParamsProps } from '../RealEstate/dtos';
import { PrepaymentOfReceivablesForm } from './form';

function PrepaymentOfReceivables(): JSX.Element {
  const { id: companyID } = useParams<ParamsProps>();
  const { company, getCompanyInfo } = useCompany();

  useEffect(() => {
    getCompanyInfo(companyID);
  }, [companyID, getCompanyInfo]);

  return (
    <Template company={company} creditType="antecipação de recebíveis">
      <PrepaymentOfReceivablesForm companyID={companyID} />
    </Template>
  );
}

export { PrepaymentOfReceivables };
