import { gql } from '@apollo/client';

export interface AddRealEstateContactMutationVars {
  companyID: string;
  name: string;
  cpf: string;
  phone: string;
  email: string;
  personType: string;
  creditType: string;
  propertyID: string;
  propertyValue: number;
  address: string;
  opportunityValue: number;
  term: number;
  birthday: Date;
}

export interface AddRealEstateContactMutationData {
  TITLE: string;
}

export interface AddRealEstateContactMutationResponse {
  addRealEstateContact: AddRealEstateContactMutationData;
}

const ADD_REAL_ESTATE_CONTACT = gql`
  mutation addRealEstateContact(
    $companyID: String!
    $name: String!
    $email: String!
    $personType: String!
    $cpf: String
    $cnpj: String
    $phone: String
    $birthday: DateTime!
    $creditType: String
    $address: String
    $propertyValue: Float
    $opportunityValue: Float
    $term: Int
    $propertyID: String
  ) {
    addRealEstateContact(
      data: {
        companyID: $companyID
        name: $name
        email: $email
        personType: $personType
        cpf: $cpf
        cnpj: $cnpj
        phone: $phone
        birthday: $birthday
        creditType: $creditType
        address: $address
        propertyValue: $propertyValue
        opportunityValue: $opportunityValue
        term: $term
        propertyID: $propertyID
      }
    ) {
      TITLE
    }
  }
`;

export default ADD_REAL_ESTATE_CONTACT;
