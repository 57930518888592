import { BaseTextFieldProps, MenuItem } from '@material-ui/core';
import { useField } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SelectInputCustomized } from './style';

interface Option {
  value: number | string;
  label: string;
}

interface SelectProps extends BaseTextFieldProps {
  name: string;
  options: Option[];
}

function Select({ name, options, ...rest }: SelectProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>();
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState<string>(defaultValue);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => value,
    });
  }, [fieldName, registerField, value]);
  return (
    <SelectInputCustomized
      inputRef={inputRef}
      variant="filled"
      inputProps={{
        name: fieldName,
        value,
      }}
      error={!!error}
      helperText={error}
      select
      fullWidth
      onChange={handleOnChange}
      {...rest}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </SelectInputCustomized>
  );
}

export default Select;
