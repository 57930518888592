const propertyTypes = [
  {
    label: 'Terreno',
    value: '49',
  },
  {
    label: 'Apartamento',
    value: '51',
  },
  {
    label: 'Sala Comercial',
    value: '53',
  },
  {
    label: 'Casa',
    value: '55',
  },
  {
    label: 'Galpão',
    value: '57',
  },
  {
    label: 'Outro',
    value: '59',
  },
];

export default propertyTypes;
